<template>
  <div class="login-bg">
    <div class="login-auto a-auto a-flex a-flex-ai-cen a-flex-jc-btw">
      <div class="login-left">
        <template v-if="code=='nazjzx'">
          <div class="login-title">职业教育中心</br> 综合素质素养发展平台</div>
        </template>
        <template v-else>
          <div class="login-title">{{title}}</div>
        </template>

        <div class="login-xbg"></div>
      </div>
      <!-- 右侧 -->
      <div class="login-right">
        <template v-if="!isSlideCheck">
          <div v-show="isForm" class="login-form">
            <div class="login-form-title">用户登录</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" @keydown.enter.native="submitForm('ruleForm')">
              <el-form-item prop="user">
                <el-select v-model="ruleForm.schoolId"
                           clearable
                           @change='schoolIdChange'
                           placeholder="请选择学校"
                           :disabled="disabled"
                           :class="{'login-form-input':true,'schoolColor':disabled}">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="username">
                <el-input placeholder="手机/邮箱/用户名" prefix-icon="el-icon-user" v-model="ruleForm.username" type="text" class="login-form-input"> </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input placeholder="密码" prefix-icon="el-icon-lock" v-model="ruleForm.password" show-password type="password" class="login-form-input"> </el-input>
              </el-form-item>
              <el-button type="primary" class="login-form-button" @click="submitForm('ruleForm')"> 登录 </el-button>
            </el-form>
            <template v-if="isWechatBtn">
              <el-divider></el-divider>
              <div class="login-other">
                <p>其他方式登录</p>
                <div @click="wxLogin">
                  <img src="@/assets/img/login/icon_wx.png" alt="" />
                </div>
              </div>
            </template>
          </div>
          <div v-show="!isForm" class="login-form">
            <el-button type="text" icon="el-icon-back" @click="backFormLogin" class="back-btn">账号登录</el-button>
            <div id="weixin">二维码</div>
          </div>
        </template>
        <template v-if="isSlideCheck">
          <div style="margin-top: 100px">
            <slide-verify :l="42" :r="10" :w="300" :h="260" @success="onSuccess" @fail="onFail" @refresh="onRefresh" :slider-text="text" :imgs="imgs"> </slide-verify>
            <div>{{ msg }}</div>
          </div>
        </template>
      </div>
    </div>
    <!-- 底部 -->
    <div class="base-footer">
      <div class="copyright">
        <span><img src="@/assets/img/base/footer_addr.png" alt="" />地址：太原市小店区龙城大街102号盛锦国际B座6层</span>
        <span> 电话：0351-5615309</span>
      </div>
      <div class="copy">
        <span style="margin-right: 60px">版权所有：山西华兴科软有限公司 晋ICP备05002232号-4</span>
        <span>建议使用360浏览器极速模式,分辨率1600*900以上访问本网站</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getSchoolList, login, wechatLogin,getSchoolId } from '@/api/login';
export default {
  data() {
    return {
      title: '职业教育评价服务云平台',
      ruleForm: {
        schoolId: '' ,
        username: '',
        password: '',
      },
      options: [], //学校下拉框
      value: '',//学校转换名称
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      disabled:false,
      msg: '',
      text: '向右滑',
      num: 0,//验证次数+
      imgs: [require('@/assets/img/slide/1.jpg'), require('@/assets/img/slide/2.jpg')],//验证图片
      code: '',
      unionid: '',
      isWechatBtn:true,
      isForm:true,
      isSlideCheck:false
    };
  },
  created() {
    this.checkBindWechat();
    this.fetchData();
  },
  methods: {
    schoolIdChange(e){

      if (e==1795269555322478593){
        this.code='nazjzx'
      } else {
        this.code=''
      }
    },
    // 获取学校列表
    fetchData() {
      getSchoolList().then(res => {
        if (res.success) {
          const arr = res.result.map(item => {
            return {
              value: item.value,
              label: item.text,
            };
          });
          this.options = arr;
          let ss_schoolId = localStorage.getItem('ss-schoolId')
          if(ss_schoolId){
            this.ruleForm.schoolId = ss_schoolId
            this.schoolIdChange(this.ruleForm.schoolId)
          }else{
            this.ruleForm.schoolId = this.options[0].value
            this.schoolIdChange(this.ruleForm.schoolId)
          }
          this.disabledSchool()
        }
      });
    },
    checkBindWechat(){
      let mycode = this.$route.query.code;
      this.code = this.$route.query.school;
      let school = this.$route.query.school
      localStorage.setItem("disabledSchool",school)
      if (mycode) {
        var params = {
          code: mycode,
        };
        wechatLogin(params).then(res => {
          if (res.success) {
            let data = res.result
            this.unionid = data.unionid;
            // 已经绑定过
            if (data.status) {
              this.$message({
                message: res.message,
                type: 'success',
              });
              localStorage.setItem('ss-token', data.token);
              localStorage.setItem('ss-schoolId', data.userInfo.schoolId);
              localStorage.setItem('ss-userInfo', JSON.stringify(data.userInfo));
              localStorage.setItem('ss-className', data.userInfo.className);
              this.$router.push('/');
            } else {
              this.$confirm('确认绑定账号登录吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success',
              }).then(() => {
                this.isWechatBtn = false;
                this.submitForm("wechatLogin");
              }).catch(()=>{
                this.$router.push(this.$route.path)
              });
            }
          } else if (res.success) {
            this.$message({
              message: res.message,
              type: 'warning',
            });
          }
        });
      }
    },
    disabledSchool(){
      let school = this.$route.query.school
      localStorage.setItem("disabledSchool",school)
      if(school){
        // TODO 运城职业技术大学临时使用
        if(school==='yczy' || school==='hxkr'){
          this.title = '质量监测管理平台'
        }
        // TODO 太原城市学院临时使用
        if(school==='csxy'){
          this.title = '多元化评价系统'
        }

        this.getSchoolId(school)
      }else{
        localStorage.removeItem("disabledSchool")
      }
    },
    getSchoolId(code){
      getSchoolId(code).then(res=>{
        if(res.success){
          this.ruleForm.schoolId = res.result.id
          this.schoolIdChange( this.ruleForm.schoolId)
          this.disabled = true;
        }
      })
    },
    // 账号密码登录
    submitForm(type) {
      if(type){
        this.ruleForm['thirdId'] = this.unionid
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 验证没超过3次
          if (this.num <= 3) {
            login(this.ruleForm).then(response => {
              if (response.success) {
                let data = response.result
                localStorage.setItem('ss-schoolId', data.userInfo.schoolId);
                localStorage.setItem('ss-className', data.userInfo.className);
                localStorage.setItem('ss-userInfo', JSON.stringify(data.userInfo));
                localStorage.setItem('ss-token', data.token);
                this.$router.push('/');
              } else {
                this.$message({
                  message: response.message,
                  type: 'warning',
                });
                this.num++;
              }
            });
          } else {
            this.isSlideCheck = true;
          }
        } else {
          return false;
        }
      });
    },
    // 微信登录
    wxLogin() {
      this.isForm = false;
      let _this = this;
      let url = this.$route.query.school ? `/login?school=${this.$route.query.school}` : '/login'
      this.$nextTick(() => {
        new WxLogin({
          id: 'weixin',
          appid: 'wxfecd712b341a1488', // 这个appid要填死
          scope: 'snsapi_login',
          // 扫码成功后重定向的接口
          redirect_uri: process.env.VUE_APP_WECHAT_URL + url,
          // state填写编码后的url
          state: encodeURIComponent(window.btoa('http://127.0.0.1:8080' + _this.$route.path)),
        });
      });
    },
    backFormLogin(){
      this.isForm = true
    },
    onSuccess() {
      this.msg = '';
      this.isSlideCheck = false;
      this.$message.success('校验成功');
      this.$nextTick(() => {
        this.num = 1;
        this.submitForm();
      });
    },
    onFail() {
      this.$message.warning('校验失败');
      this.msg = '';
    },
    onRefresh() {
      this.msg = '';
    },
  },
};
</script>

<style scoped lang="scss">
/* 水平垂直居中 */
.a-auto {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
/* flex布局 */
.a-flex {
  display: flex;
}
.a-flex-jc-cen {
  justify-content: center;
}
.a-flex-jc-btw {
  justify-content: space-between;
}
.a-flex-jc-ard {
  justify-content: space-around;
}
.a-flex-ai-cen {
  align-items: center;
}
.back-btn{
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: left;
}
#weixin {
  display: flex;
  justify-content: center;
}
.login-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../assets/img/login/login_bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.login-auto {
  width: 1000px;
  height: 522px;
  margin: 0 auto;
  display: flex;
}
.login-left {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  .login-title{
    text-align: center;
    font-size: 40px;
    color: #fff;
  }
  .login-xbg{
    flex: 1;
    background: url(~@/assets/img/login/photo_01.png) no-repeat left center;
    background-size: contain;
  }
}
.login-right {
  width: 375px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #fff;
  padding: 30px 60px 20px;
  box-sizing: border-box;
  position: relative;
  .login-form-title {
    text-align: center;
    font-size: 30px;
    color: #0072ff;
    font-weight: bold;
    letter-spacing: 2px;
  }
  form {
    margin: 26px 0 20px;
  }
  /deep/.schoolColor input{
    color: rgba(0, 0, 0, 0.65)
  }

  .login-form-input,
  .login-form-button{
    width: 100%;
  }
  .login-other {
    margin: 0 auto;
    p {
      line-height: 28px;
      font-size: 15px;
      text-align: center;
      user-select: none;
      color: #ccc;
    }
    img {
      display: block;
      margin: 10px auto;
      width: 30px;
      cursor: pointer;
    }
  }
}
.base-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 66px;
  background-color: rgba(51, 177, 244, 0.6);
  color: #ffffff;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  .copyright {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: 16px;
        margin-right: 14px;
      }
      &:first-child {
        margin-right: 44px;
      }
    }
  }
}
</style>
